import React, { useState, useEffect } from "react";
import { Navbar, Nav, Container, Dropdown, Alert } from "react-bootstrap";
import { Link, useLocation, useParams } from "react-router-dom";
import useSignOut from "../../../shared/hooks/useSignOut";
import useFetchFields from "../../../shared/hooks/useFetchFields";
import useCheckAdminStatus from "../../../shared/hooks/useCheckAdminStatus";
import Loading from "../../Loading/Components/Loading";
import willCloud from "shared/images/willcloud-logo-white.png";
import { FaUserCircle } from "react-icons/fa";
import "../Css/navbar.css";

const CustomNavbar = () => {
  const [expanded, setExpanded] = useState(false);
  const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth > 991);
  const location = useLocation();
  const { field } = useParams();
  const fieldInfo = location.state?.fieldInfo || {};
  const handleSignOut = useSignOut();
  const {
    fields,
    loading: fieldsLoading,
    error: fieldsError,
  } = useFetchFields();
  const {
    isAdmin,
    isWill,
    loading: adminLoading,
    error: adminError,
  } = useCheckAdminStatus();

  useEffect(() => {
    const handleResize = () => {
      setIsLargeScreen(window.innerWidth > 991);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  if (fieldsLoading || adminLoading) {
    return <Loading />;
  }

  if (fieldsError || adminError) {
    return (
      <Alert variant="danger">
        {fieldsError ? "Error loading fields data." : ""}
        {adminError ? "Error checking admin status." : ""}
      </Alert>
    );
  }

  const handleNavClick = () => {
    setExpanded(false);
  };

  return (
    <Navbar
      expanded={expanded}
      onToggle={() => setExpanded(!expanded)}
      expand="lg"
      variant="dark"
      className="custom-navbar"
      style={{
        borderBottom: "2px solid #FFCF2E",
        height: "80px",
        margin: "0 auto",
      }}
    >
      <Container fluid>
        <Navbar.Brand as={Link} to="/" onClick={handleNavClick}>
          <img src={willCloud} alt="logo" className="willcloud-logo" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mx-auto">
            <Nav.Link
              as={Link}
              to={`/`}
              state={{ fieldInfo }}
              className={`nav-link ${
                location.pathname === "/" ? "active" : ""
              }`}
              onClick={handleNavClick}
            >
              Selector
            </Nav.Link>
            {field && (
              <>
                <Nav.Link
                  as={Link}
                  to={`/${field}/controls`}
                  state={{ fieldInfo }}
                  className={`nav-link ${
                    location.pathname.includes("/controls") ? "active" : ""
                  }`}
                  onClick={handleNavClick}
                >
                  Controls
                </Nav.Link>
                <Nav.Link
                  as={Link}
                  to={`/${field}/schedule`}
                  state={{ fieldInfo }}
                  className={`nav-link ${
                    location.pathname.includes("/schedule") ? "active" : ""
                  }`}
                  onClick={handleNavClick}
                >
                  Schedule
                </Nav.Link>
                <Nav.Link
                  as={Link}
                  to={`/${field}/account`}
                  state={{ fieldInfo }}
                  className={`nav-link ${
                    location.pathname.includes("/account") ? "active" : ""
                  }`}
                  onClick={handleNavClick}
                >
                  Users
                </Nav.Link>
              </>
            )}

            {!isLargeScreen && (
              <>
                {isWill && (
                  <Nav.Link
                    as={Link}
                    to="/Manage"
                    className={`${
                      location.pathname === "/Manage" ? "active" : ""
                    }`}
                    onClick={handleNavClick}
                  >
                    WiLL User Manage
                  </Nav.Link>
                )}
                <Nav.Link
                  onClick={() => {
                    handleSignOut();
                    handleNavClick();
                  }}
                >
                  Sign Out
                </Nav.Link>
              </>
            )}
          </Nav>

          <Nav className="ms-5 ps-5">
            <Dropdown align="end">
              <Dropdown.Toggle
                variant="link"
                id="dropdown-basic"
                aria-label="User Options"
              >
                <FaUserCircle size={30} className="user-icon" />
              </Dropdown.Toggle>

              <Dropdown.Menu align="end">
                {isWill && (
                  <Dropdown.Item
                    as={Link}
                    to="/Manage"
                    className={`${
                      location.pathname === "/Manage" ? "active" : ""
                    }`}
                    onClick={handleNavClick}
                  >
                    WiLL User Manage
                  </Dropdown.Item>
                )}
                <Dropdown.Item
                  onClick={() => {
                    handleSignOut();
                    handleNavClick();
                  }}
                >
                  Sign Out
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default CustomNavbar;
