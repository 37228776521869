// index.js
import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App"; // The main App component
import { Amplify } from "aws-amplify";
import awsconfig from "./aws-exports";

// Import your global styles here
import "shared/css/global.css";
import "shared/css/card.css";
import "shared/css/button.css";
import "shared/css/variables.css";

Amplify.configure(awsconfig);

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
