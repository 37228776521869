import { useNavigate } from "react-router-dom";
import { signOut } from "aws-amplify/auth";

const useSignOut = () => {
  const navigate = useNavigate();

  const handleSignOut = async () => {
    try {
      await signOut();
      navigate("/login");
    } catch (error) {
      console.error("Error signing out:", error);
    }
  };

  return handleSignOut;
};

export default useSignOut;