import React, { useEffect, useState } from "react";
import {
  Card,
  Col,
  Row,
  Container,
  Button,
  ListGroup,
  Spinner,
  Modal,
} from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { DataStore } from "@aws-amplify/datastore";
import { User, UserPoolUser } from "models";
import { fetchUserPermissions } from "shared/utils/Utils";
import useUserPool from "shared/hooks/useSetUserPoolID";
import "bootstrap-icons/font/bootstrap-icons.css"; // Import Bootstrap icons
import "../Css/users.css";

function ManageFieldUsers() {
  const location = useLocation();
  const fieldInfo = location.state?.fieldInfo || {};
  const { userPoolId, isLoading: poolLoading, error } = useUserPool(fieldInfo);
  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [userPools, setUserPools] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [userToDelete, setUserToDelete] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        if (userPoolId) {
          const userPoolUsers = await DataStore.query(UserPoolUser, (c) =>
            c.userPoolId.eq(userPoolId)
          );
          const userIds = userPoolUsers.map((upu) => upu.userId);
          const users = await Promise.all(
            userIds.map((id) =>
              DataStore.query(User, (u) => u.id.eq(id)).then(
                (result) => result[0]
              )
            )
          );

          const filteredUsers = users.filter(
            (user) => !user.email.endsWith("@willbrands.com")
          );

          setUsers(
            filteredUsers.sort((a, b) => a.email.localeCompare(b.email))
          );
        }
      } catch (error) {
        console.error("Error loading users and user pools:", error);
      } finally {
        setIsLoading(false);
      }
    };
    if (userPoolId) {
      fetchData();
    }
  }, [userPoolId]);

  const handleSelectUser = async (userId) => {
    const user = users.find((u) => u.id === userId);
    setSelectedUser(user);
    if (user) {
      const permissions = await fetchUserPermissions(user.id);
      setUserPools((prevPools) =>
        prevPools.map((pool) => ({
          ...pool,
          selected: permissions.some((p) => p.userPoolId === pool.id),
        }))
      );
    }
  };

  const handleShowDeleteModal = (user) => {
    setUserToDelete(user);
    setShowDeleteModal(true);
  };

  const handleCloseDeleteModal = () => {
    setUserToDelete(null);
    setShowDeleteModal(false);
  };

  const handleDeleteUser = async () => {
    if (userToDelete) {
      try {
        const userAssociations = await DataStore.query(UserPoolUser, (c) =>
          c.userId.eq(userToDelete.id)
        );
        await Promise.all(
          userAssociations.map((assoc) => DataStore.delete(assoc))
        );
        await DataStore.delete(userToDelete);
        setUsers(users.filter((user) => user.id !== userToDelete.id));
      } catch (error) {
        console.error("Error deleting user:", error);
      } finally {
        handleCloseDeleteModal();
      }
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!selectedUser) {
      console.error("No user selected");
      return;
    }

    const existingAssociations = await DataStore.query(UserPoolUser, (c) =>
      c.userId.eq(selectedUser.id)
    );
    await Promise.all(
      existingAssociations.map((assoc) => DataStore.delete(assoc))
    );

    const selectedPools = userPools.filter((pool) => pool.selected);
    for (const pool of selectedPools) {
      await DataStore.save(
        new UserPoolUser({ userPoolId: pool.id, userId: selectedUser.id })
      );
    }

    console.log("Updated user pools for:", selectedUser.email);
    alert("User pools updated.");
  };

  if (isLoading || poolLoading) {
    return (
      <Container
        className="d-flex justify-content-center align-items-center"
        style={{ height: "100vh" }}
      >
        <Spinner animation="border" variant="primary" />
        <span className="ml-2">Fetching Users...</span>
      </Container>
    );
  }

  return (
    <Card className="card-custom-width">
      <Card.Header as="h4">Manage Users</Card.Header>
      <Card.Body>
        <ListGroup style={{ width: "100%" }}>
          {users.map((user) => (
            <ListGroup.Item
              key={user.id}
              onClick={() => handleSelectUser(user.id)}
              className="user-list-item d-flex justify-content-between align-items-center"
            >
              <span className="user-email">{user.email}</span>
              <Button
                variant="outline-danger"
                className="remove-button"
                style={{ fontSize: ".889rem", textWrap: "nowrap" }}
                onClick={(e) => {
                  e.stopPropagation(); // Prevent the ListGroup.Item onClick from being triggered
                  handleShowDeleteModal(user);
                }}
              >
                REMOVE
              </Button>
            </ListGroup.Item>
          ))}
        </ListGroup>
      </Card.Body>

      <Modal show={showDeleteModal} onHide={handleCloseDeleteModal}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Deletion</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this user?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseDeleteModal}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleDeleteUser}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </Card>
  );
}

export default ManageFieldUsers;
