import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getUserInfo } from "shared/utils/Utils.js";
import Loading from "features/Loading/Components/Loading.js";
import { Button } from "react-bootstrap";
import ".././Css/fieldSelector.css";

function FieldSelector() {
  const navigate = useNavigate();
  const [fields, setFields] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    async function fetchData() {
      try {
        const { usersFields } = await getUserInfo();
        // Sort fields alphabetically by name
        const sortedFields = usersFields.sort((a, b) =>
          a.name.localeCompare(b.name)
        );
        setFields(sortedFields || []);

        // If there's only one field, navigate directly to that field
        if (sortedFields.length === 1) {
          openField(sortedFields[0]);
        }

        setLoading(false);
      } catch (error) {
        setError(error.message);
        setLoading(false);
      }
    }
    fetchData();
  }, []);

  const openField = (fieldInfo) => {
    try {
      const fieldName = fieldInfo.name.replace(/[.\s]+/g, "").toLowerCase();
      navigate(`/${fieldName}/controls`, { state: { fieldInfo } });
    } catch (error) {
      console.error("Error navigating to field:", error);
    }
  };

  if (loading) return <Loading />;
  if (error) return <div>Error: {error}</div>;

  return (
    <div className="field-container">
      {fields.length > 1 ? (
        fields.map((field) => (
          <Button
            key={field.id}
            className="selector-button"
            onClick={() => openField(field)}
          >
            {field.name}
          </Button>
        ))
      ) : (
        <div className="text-center">
          <p>No fields available.</p>
        </div>
      )}
    </div>
  );
}

export default FieldSelector;
