import React from "react";
import "../Css/offline.css";

function OfflinePage({ onRetry }) {
  return (
    <div className="offline-message">
      <h4>Device is Offline</h4>
      <p className="mb-4">
        The device is currently offline, but we are actively working to restore
        the connection. The controls will become available once the device is
        back online.
      </p>

      <p>
        If the issue persists, please restart the device and try again after it
        reboots.
      </p>

      <p>
        For further assistance, please call us at 866-308-9455 or email:
        support@willbrands.com.
      </p>
    </div>
  );
}

export default OfflinePage;
