// hooks/useLightingControls.js
import { useState, useEffect } from "react";
import {
  sendDimmingMessage,
  sendColorMessage,
  sendControlMessage,
} from "features/Controls/Utils/MessageUtils"; // Utility functions for sending messages

const useLightingControls = (shadowState, fieldInfo) => {
  const thingName = fieldInfo.IOTThingName;
  const [clickedButton, setClickedButton] = useState(null);
  const [selectedButtons, setSelectedButtons] = useState({});
  const [selectedColors, setSelectedColors] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [hasActiveScene, setHasActiveScene] = useState({});

  useEffect(() => {
    processShadowState(shadowState);
  }, [shadowState]);

  const processShadowState = (shadowState) => {
    const newSelectedButtons = {};
    const newSelectedColors = {};
    const newHasActiveScene = {};

    for (const key in shadowState) {
      const value = shadowState[key];
      let buttonKey;
      let zone;

      if (key.startsWith("levelWhite")) {
        zone = key.replace("levelWhite", "");
        const level = Math.round(
          (parseInt(value.split("#")[1], 10) / 255) * 100
        );
        buttonKey = zone ? `White-${zone}-${level}` : `dimmer-White-${level}`;
      } else if (key.startsWith("levelRgb")) {
        zone = key.replace("levelRgb", "");
        const level = Math.round(
          (parseInt(value.split("#")[1], 10) / 255) * 100
        );
        buttonKey = zone ? `Rgb-${zone}-${level}` : `dimmer-Rgb-${level}`;
      } else if (key.startsWith("valueRgb")) {
        zone = key.replace("valueRgb", "");
        const color = value.split("{")[1].split("}")[0];
        buttonKey = zone ? `color-${zone}-${color}` : `color-${color}`;
        newSelectedColors[zone] = color;
      } else if (key.startsWith("active")) {
        const type = key.match(/Scene|Pattern|Effect|Music|Schedule/)[0];
        zone = key.replace(`active${type}`, "");
        buttonKey = zone ? `${type}-${zone}-${value}` : `${type}-${value}`;
        if (key.startsWith("activeScene") && value !== "No Scene") {
          newHasActiveScene[zone] = true; // Set active scene state per zone
        }
      }

      if (buttonKey) {
        newSelectedButtons[buttonKey] = true;
      }
    }

    setHasActiveScene(newHasActiveScene);
    setSelectedButtons(newSelectedButtons);
    setSelectedColors(newSelectedColors);
    setIsLoading(false); // Data is loaded and processed
  };

  const handleDimmerButtonClick = (zone, type, command, value) => {
    const sanitizedZone = zone.replace(/[.\s]+/g, "");
    sendDimmingMessage(
      sanitizedZone,
      type,
      command,
      value,
      thingName,
      fieldInfo
    )
      .then(() => console.log("Dimming message sent successfully"))
      .catch((e) => console.error("Failed to send dimming message", e));
    setClickedButton(`dimmer-${zone}-${type}-${value}`);
  };

  const handleColorButtonClick = (zone, command, value) => {
    const sanitizedZone = zone.replace(/[.\s]+/g, "");
    sendColorMessage(sanitizedZone, command, value, thingName, fieldInfo)
      .then(() => console.log("Color message sent successfully"))
      .catch((e) => console.error("Failed to send color message", e));

    const rgbValue = value
      .split(",")
      .map((v) => v.replace("#", ""))
      .join(",");
    setClickedButton(`color-${zone}-${value}`);
    setSelectedColors((prevColors) => ({
      ...prevColors,
      [zone]: rgbValue,
    }));
  };

  const handleControlButtonClick = (zone, type, value) => {
    const sanitizedZone = zone ? zone.replace(/\s+/g, "") : "";

    sendControlMessage(sanitizedZone, type, value, thingName, fieldInfo)
      .then(() => console.log("Control message sent successfully"))
      .catch((e) => console.error("Failed to send control message", e));
    setClickedButton(`scene-${zone}-${type}-${value}`);
  };

  const isButtonSelected = (zone, type, value) => {
    // Early return if value is null
    if (value === null) {
      return;
    }
    const sanitizedZone = zone ? zone.replace(/\s+/g, "") : "";
    const buttonId = zone
      ? `${type}-${sanitizedZone}-${value}`
      : `${type}-${value}`;
    return selectedButtons[buttonId] || clickedButton === buttonId;
  };

  return {
    clickedButton,
    selectedButtons,
    selectedColors,
    isLoading,
    hasActiveScene,
    handleDimmerButtonClick,
    handleColorButtonClick,
    handleControlButtonClick,
    isButtonSelected,
  };
};

export default useLightingControls;
