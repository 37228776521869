import React, { useState } from "react";
import { Form, Button, Col, Row, Dropdown, Alert, Card } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment-timezone";
import { useLocation } from "react-router-dom";
import { getRGBColor } from "../../Controls/Utils/ColorUtility";
import axios from "axios";
import "../Css/scheduler.css";

const getCurrentTime = () => {
  const now = new Date();
  const hours = String(now.getHours()).padStart(2, "0");
  const minutes = String(now.getMinutes()).padStart(2, "0");
  return `${hours}:${minutes}`;
};

const ScheduleForm = ({ addSchedule, fetchSchedules }) => {
  const location = useLocation();
  const fieldInfo = location.state?.fieldInfo || {};
  const zoneValues = fieldInfo.zoneValues || [];
  const sceneValues = fieldInfo.sceneValues || [];
  const whiteDimmerValues = fieldInfo.whiteDimmerValues || [];
  const colorValues = fieldInfo.colorValues || [];
  const iotName = fieldInfo.IOTThingName || "";
  const whiteDimmerCommands = fieldInfo.whiteCommands || [];
  const colorCommands = fieldInfo.colorCommands || [];

  const [name, setName] = useState("");
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [startTime, setStartTime] = useState(getCurrentTime());
  const [endTime, setEndTime] = useState("23:59");
  const [selectedArea, setSelectedArea] = useState("");
  const [selectedAction, setSelectedAction] = useState("");
  const [repeat, setRepeat] = useState(false);
  const [repeatDays, setRepeatDays] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");

  const daysOfWeek = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  const convertToPercentage = (value) => Math.round((value / 255) * 100);

  const convertToEpochTime = (date, time) => {
    return Math.floor(
      moment.tz(`${date}T${time}:00`, moment.tz.guess()).valueOf() / 1000
    );
  };

  const resetForm = () => {
    setName("");
    setStartDate(new Date());
    setEndDate(new Date());
    setStartTime(getCurrentTime());
    setEndTime("22:00");
    setSelectedArea("");
    setSelectedAction("");
    setRepeat(false);
    setRepeatDays([]);
  };

  const handleSave = async () => {
    if (!name) {
      setErrorMessage("Name is required.");
      return;
    }
    if (!selectedArea) {
      setErrorMessage("Please select an area.");
      return;
    }
    if (!selectedAction) {
      setErrorMessage("Please select an action.");
      return;
    }
    if (
      moment(endDate).isBefore(moment(startDate)) ||
      (moment(endDate).isSame(moment(startDate)) &&
        moment(endTime, "HH:mm").isBefore(moment(startTime, "HH:mm")))
    ) {
      setErrorMessage("Your end time must be after your start time.");
      return;
    }

    setErrorMessage("");

    const startEpochTime = convertToEpochTime(
      moment(startDate).format("YYYY-MM-DD"),
      startTime
    );
    const endEpochTime = convertToEpochTime(
      moment(endDate).format("YYYY-MM-DD"),
      endTime
    );

    const zoneIndex = zoneValues.indexOf(selectedArea);
    const selectedCommand =
      zoneIndex < whiteDimmerCommands.length
        ? whiteDimmerCommands[zoneIndex]
        : colorCommands[zoneIndex];

    const newSchedule = {
      name,
      startDate: startEpochTime,
      endDate: endEpochTime,
      selectedArea,
      selectedAction,
      repeat,
      repeatDays,
      iotName,
      command: selectedCommand,
    };

    try {
      const response = await axios.post(
        "https://j3pp969u1j.execute-api.us-west-2.amazonaws.com/prod/",
        newSchedule
      );
      console.log(newSchedule);
      fetchSchedules();
    } catch (error) {
      console.error("Error saving schedule:", error);
    }

    resetForm();
  };

  const handleRepeatDayChange = (day) => {
    setRepeatDays((prevDays) =>
      prevDays.includes(day)
        ? prevDays.filter((d) => d !== day)
        : [...prevDays, day]
    );
  };

  const getDropdownMenuItems = () => {
    const zoneIndex = zoneValues.indexOf(selectedArea);
    const whiteItems =
      zoneIndex < whiteDimmerCommands.length
        ? whiteDimmerValues.map((value, index) => (
            <Dropdown.Item
              key={`white-${zoneIndex}-${index}`}
              eventKey={`${convertToPercentage(value)}%`}
            >
              {`${convertToPercentage(value)}%`} - White
            </Dropdown.Item>
          ))
        : [];

    const colorItems =
      zoneIndex < colorCommands.length
        ? colorValues.map((value, index) => (
            <Dropdown.Item key={`color-${zoneIndex}-${index}`} eventKey={value}>
              <span
                style={{
                  display: "inline-block",
                  width: "20px",
                  height: "20px",
                  backgroundColor: getRGBColor(index),
                  marginRight: "8px",
                }}
              ></span>{" "}
              - Color
            </Dropdown.Item>
          ))
        : [];

    return [...whiteItems, ...colorItems];
  };

  return (
    <Card className="scheduler-card">
      {errorMessage && (
        <Alert variant="danger" className="mt-3">
          {errorMessage}
        </Alert>
      )}
      <Form>
        <Form.Group as={Row} controlId="formName" className="mb-3">
          <Form.Label column sm={2}>
            Name
          </Form.Label>
          <Col sm={4}>
            <Form.Control
              type="text"
              className="input-field name-field"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
          </Col>
        </Form.Group>

        {/* Group Start Date and Start Time */}
        <Form.Group as={Row} controlId="formStartDateTime" className="mb-3">
          <Form.Label column sm={2}>
            Start Date & Time
          </Form.Label>
          <Col sm={2} style={{ minWidth: "190px" }}>
            <DatePicker
              selected={startDate}
              className="input-field"
              onChange={(date) => setStartDate(date)}
              dateFormat="MM/dd/yyyy"
              style={{ width: "190px" }}
              customInput={
                <Form.Control
                  type="text"
                  value={moment(startDate).format("MM/DD/YYYY")}
                  readOnly
                  required
                />
              }
            />
          </Col>
          <Col sm={2} style={{ minWidth: "190px" }}>
            <Form.Control
              type="time"
              value={startTime}
              className="input-field"
              onChange={(e) => setStartTime(e.target.value)}
              required
            />
          </Col>
        </Form.Group>

        {/* Group End Date and End Time */}
        <Form.Group as={Row} controlId="formEndDateTime" className="mb-3">
          <Form.Label column sm={2}>
            End Date & Time
          </Form.Label>
          <Col sm={2} style={{ minWidth: "190px" }}>
            <DatePicker
              selected={endDate}
              className="input-field"
              onChange={(date) => setEndDate(date)}
              dateFormat="MM/dd/yyyy"
              style={{ width: "190px" }}
              customInput={
                <Form.Control
                  type="text"
                  value={moment(endDate).format("MM/DD/YYYY")}
                  readOnly
                  required
                />
              }
            />
          </Col>
          <Col sm={2} style={{ minWidth: "190px" }}>
            <Form.Control
              type="time"
              className="input-field"
              value={endTime}
              onChange={(e) => setEndTime(e.target.value)}
              required
            />
          </Col>
        </Form.Group>

        <Form.Group as={Row} controlId="formArea" className="mb-3">
          <Form.Label column sm={2} style={{ minWidth: "190px" }}>
            Select Zone
          </Form.Label>
          <Col style={{ display: "flex", justifySelf: "start" }} sm={10}>
            <Dropdown onSelect={(e) => setSelectedArea(e)}>
              <Dropdown.Toggle
                style={{
                  display: "flex",
                  width: "190px",
                  justifySelf: "start",
                }}
                variant="secondary"
                id="dropdown-area"
              >
                {selectedArea || "Select Zone"}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {zoneValues.map((zone, index) => (
                  <Dropdown.Item key={index} eventKey={zone}>
                    {zone}
                  </Dropdown.Item>
                ))}
                <Dropdown.Item eventKey="Scenes">Scenes</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Col>
        </Form.Group>

        <Form.Group as={Row} controlId="formAction" className="mb-3">
          <Form.Label column sm={2} style={{ minWidth: "190px" }}>
            Select Action
          </Form.Label>
          <Col style={{ display: "flex", justifySelf: "start" }} sm={10}>
            <Dropdown onSelect={(e) => setSelectedAction(e)}>
              <Dropdown.Toggle
                style={{
                  display: "flex",
                  width: "190px",
                  justifySelf: "start",
                }}
                variant="secondary"
                id="dropdown-action"
              >
                {selectedAction || "Select Action"}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {selectedArea === "Scenes"
                  ? sceneValues.map((scene, index) => (
                      <Dropdown.Item key={index} eventKey={scene}>
                        {scene}
                      </Dropdown.Item>
                    ))
                  : getDropdownMenuItems()}
              </Dropdown.Menu>
            </Dropdown>
          </Col>
        </Form.Group>

        <Form.Group as={Row} controlId="formRepeat" className="mb-3">
          <Form.Label column sm={2}>
            Repeat
          </Form.Label>
          <Col sm={10} style={{ display: "flex", alignItems: "center" }}>
            <Form.Check
              type="checkbox"
              checked={repeat}
              onChange={(e) => setRepeat(e.target.checked)}
            />
          </Col>
        </Form.Group>

        {repeat && (
          <Form.Group as={Row} controlId="formRepeatDays" className="mb-3">
            <Form.Label column sm={2}>
              Repeat Days
            </Form.Label>
            <Col sm={10}>
              {daysOfWeek.map((day, index) => (
                <Form.Check
                  key={index}
                  type="checkbox"
                  style={{ display: "flex", alignItems: "flex-end" }}
                  label={day}
                  checked={repeatDays.includes(day)}
                  onChange={() => handleRepeatDayChange(day)}
                />
              ))}
            </Col>
          </Form.Group>
        )}

        <Row className="mb-3">
          <Col sm={2}></Col>
          <Col sm={4} style={{ paddingLeft: "-10px" }}>
            <Button
              variant="primary"
              onClick={handleSave}
              className="schedule-btn"
              style={{ marginLeft: "-10px !important" }}
            >
              Save
            </Button>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};

export default ScheduleForm;
