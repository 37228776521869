// colorUtils.js

// RGB display values
export const rgbDisplay = [
    '204,30,0', '0,199,0', '3,40,204', '203,200,0',
    '0,201,204', '204,117,2', '204,0,152', '140,0,204', '0,105,204'
  ];
  
  // Function to get the displayable RGB color
  export const getRGBColor = (index) => {
    return `rgb(${rgbDisplay[index % rgbDisplay.length]})`;
  };