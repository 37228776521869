import { getRGBColor } from "./ColorUtility";

// Function to determine the background color for the current zone
export function getBackgroundColor(zone, RGBValues, selectedColors) {
  zone = zone.replace(/[.\s]+/g, "");
  const selectedColorCode = selectedColors[zone]; // Get the selected color code for the current zone

  if (!selectedColorCode) {
    return "transparent"; // Fallback color if none is selected
  }

  // Assuming selectedColorCode is in the format "#RRGGBB"
  const cleanColor = selectedColorCode.replace(/#/g, ""); // Remove '#' for matching

  // Find the index in RGBValues that matches the cleanColor
  // Assuming RGBValues contains color codes in the "RRGGBB" format without '#'
  const num = RGBValues.findIndex((color) => color === cleanColor);

  if (num === -1) {
    return "transparent"; // Or any fallback color
  } else {
    // Convert to displayable RGB format
    return getRGBColor(num);
  }
}
