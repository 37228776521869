// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';



const { Role, UserPool, User, Field, RoleUser, UserPoolRole, UserPoolUser } = initSchema(schema);

export {
  Role,
  UserPool,
  User,
  Field,
  RoleUser,
  UserPoolRole,
  UserPoolUser
};