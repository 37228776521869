import React, { useState, useEffect } from "react";
import { useColorWheelState } from "@react-stately/color";
import { parseColor } from "@react-stately/color";
import { Card, Row, Col, Button } from "react-bootstrap"; // Use React Bootstrap
import ColorWheel from "features/Controls/Utils/ColorWheelUtil";

function ColorWheelSelector({
  zone,
  RGBCommands,
  handleColorButtonClick,
  isButtonSelected,
  selectedColors,
  hasRGBDimmers,
}) {
  const state = useColorWheelState({
    defaultValue: parseColor(initialColor(zone, selectedColors)),
  });

  useEffect(() => {
    const newColor = initialColor(zone, selectedColors);
    state.setValue(parseColor(newColor));
  }, [zone, selectedColors]);

  function initialColor(zone, selectedColors) {
    let cleanZone = zone.replace(/[.\s]+/g, "");
    if (!selectedColors[cleanZone]) {
      selectedColors[cleanZone] = "255,255,0";
    }
    const selectedColorCode = selectedColors[cleanZone];
    const cleanColor = selectedColorCode.replace(/#/g, "");
    const rgbValues = cleanColor.split(",").map(Number);
    const [h, s, l] = rgbToHsl(...rgbValues);
    return `hsl(${h}, ${s}%, ${l}%)`;
  }

  function rgbToHsl(r, g, b) {
    r /= 255;
    g /= 255;
    b /= 255;
    const max = Math.max(r, g, b),
      min = Math.min(r, g, b);
    let h,
      s,
      l = (max + min) / 2;

    if (max === min) {
      h = s = 0;
    } else {
      const d = max - min;
      s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
      switch (max) {
        case r:
          h = (g - b) / d + (g < b ? 6 : 0);
          break;
        case g:
          h = (b - r) / d + 2;
          break;
        case b:
          h = (r - g) / d + 4;
          break;
      }
      h /= 6;
    }
    return [Math.round(h * 360), Math.round(s * 100), Math.round(l * 100)];
  }

  function handleColorChangeEnd(rgbString) {
    const rgbValues = rgbString.match(/\d+/g);
    if (!rgbValues) {
      console.error("Invalid RGB string format:", rgbString);
      return;
    }
    const formattedColorString = rgbValues.map((v) => `#${v}`).join(",");
    handleColorButtonClick(zone, RGBCommands, formattedColorString);
  }

  const handleRedClick = () =>
    handleColorButtonClick(zone, RGBCommands, "#255,#0,#0");
  const handleGreenClick = () =>
    handleColorButtonClick(zone, RGBCommands, "#0,#255,#0");
  const handleBlueClick = () =>
    handleColorButtonClick(zone, RGBCommands, "#0,#0,#255");

  return (
    <Card.Body>
      <Card.Title as="h6" style={{ color: "white" }}>
        Color Selector
      </Card.Title>

      <div
        style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}
      >
        <ColorWheel
          value={state.value}
          onChangeEnd={(color) => handleColorChangeEnd(color.toString("rgb"))}
          style={{ maxWidth: "200px" }}
        />
      </div>

      {/* Two buttons on top */}
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: "20px",
          gap: "10px",
          width: "100%",
        }}
      >
        <Button className="button-style" onClick={handleRedClick}>
          Red
        </Button>
        <Button className="button-style" onClick={handleGreenClick}>
          Green
        </Button>
      </div>

      {/* One button on the bottom */}
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: "10px",
          width: "100%",
        }}
      >
        <Button className="button-style" onClick={handleBlueClick}>
          Blue
        </Button>
      </div>

      {!hasRGBDimmers && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "10px",
            width: "100%",
          }}
        >
          <Button
            key={`color-no-${zone}`}
            className={`no-blank ${
              isButtonSelected(zone, "color", "#0,#0,#0")
                ? "selected-button"
                : ""
            }`}
            onClick={() => {
              handleColorButtonClick(zone, RGBCommands, "#0,#0,#0");
            }}
          >
            No Color
          </Button>
        </div>
      )}
    </Card.Body>
  );
}

export default ColorWheelSelector;
