import React from "react";
import { Col, Row } from "react-bootstrap";
import InviteUserToField from "./InviteUserToField";
import ManageFieldUsers from "./ManageFieldUsers";
import "../Css/users.css";

const Account = () => {
  return (
    <Row>
      <InviteUserToField />
      <ManageFieldUsers />
    </Row>
  );
};

export default Account;
