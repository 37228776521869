import { Flex, Link, useAuthenticator, useTheme } from "@aws-amplify/ui-react";
/**
 * Defines the footer element of the sign in screen for authentication 
 */

function SignInFooter() {
    const { toForgotPassword } = useAuthenticator();
    const { tokens } = useTheme();

    return (
        <Flex justifyContent="center" padding={`0 0 ${tokens.space.medium}`} backgroundColor={"white"}>
            <Link onClick={toForgotPassword}>Reset your password</Link>
        </Flex>
    );
}

export default SignInFooter;