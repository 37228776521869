import { LogShadowUpdate } from "../../../shared/utils/Utils";
import { pubsub } from "../../../shared/utils/Pubsub";

export const sendDimmingMessage = async (
  zone,
  type,
  command,
  value,
  thingName,
  fieldInfo
) => {
  try {
    const level = Math.round((value / 100) * 255);
    const messageKey = `level${type}${zone}`;
    const messageValue = `${command}#${level}`;
    const projectName = fieldInfo.name;
    const UPDATE_TOPIC = `$aws/things/${thingName}/shadow/update`;

    const payload = {
      state: {
        desired: {
          [messageKey]: messageValue,
        },
      },
    };

    await pubsub.publish({ topics: UPDATE_TOPIC, message: payload }); // Corrected here
    LogShadowUpdate(payload, projectName);
  } catch (e) {
    console.error(`Send${type}DimmingMessage error:`, e);
  }
};

export const sendColorMessage = async (
  zone,
  command,
  value,
  thingName,
  fieldInfo
) => {
  try {
    const rgbValues = value.split(",");
    const messageKey = `valueRgb${zone}`;
    const messageValue = `${command}{${rgbValues.join(",")}}`;

    const projectName = fieldInfo.name;
    const UPDATE_TOPIC = `$aws/things/${thingName}/shadow/update`;

    const payload = {
      state: {
        desired: {
          [messageKey]: messageValue,
        },
      },
    };

    await pubsub.publish({ topics: UPDATE_TOPIC, message: payload }); // Corrected here
    LogShadowUpdate(payload, projectName);
  } catch (e) {
    console.error("SendColorMessage error:", e);
  }
};

export const sendControlMessage = async (
  zone,
  type,
  value,
  thingName,
  fieldInfo
) => {
  try {
    const messageKey = `active${type}${zone}`;
    const messageValue = `${value}`;
    const projectName = fieldInfo.name;
    const UPDATE_TOPIC = `$aws/things/${thingName}/shadow/update`;

    const payload = {
      state: {
        desired: {
          [messageKey]: messageValue,
        },
      },
    };

    await pubsub.publish({ topics: UPDATE_TOPIC, message: payload }); // Corrected here
    LogShadowUpdate(payload, projectName);
  } catch (e) {
    console.error(`Send${type}ControlMessage error:`, e);
  }
};
