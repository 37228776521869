import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "../Css/footer.css";

/**
 * Defines the footer element of the authentication screen
 */
function Footer() {
  return (
    <footer>
      <Container fluid>
        <Row className="align-items-center" style={{ fontSize: ".75rem" }}>
          {/* Logo Column */}
          <Col xs="auto" className="footer-col">
            <a
              href="https://www.willbrands.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                alt="logo"
                src="https://cdn.shopify.com/s/files/1/0672/9888/5881/files/reg-will-logo-fullColorBlackBG_200x.png?v=1664852398"
                style={{ maxWidth: "100px" }} // Adjust the size of the logo
              />
            </a>
          </Col>
          {/* Copyright Column */}
          <Col xs="auto" className="footer-col" style={{ color: "white" }}>
            WiLLCloud &copy; 2024
          </Col>
          {/* Additional Info Column */}
          <Col xs="auto" className="footer-col" style={{ color: "white" }}>
            WiLLCloud is a web application of Wisconsin Lighting Lab, Inc. Visit
            us at{" "}
            <a
              href="https://www.willbrands.com"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: "white", textDecoration: "underline" }}
            >
              WiLLbrands.com
            </a>
            .
          </Col>
        </Row>
      </Container>
    </footer>
  );
}

export default Footer;
