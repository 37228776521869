import React, { useState } from "react";
import { Card, Col, Row, Container, Form, Button } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { DataStore } from "@aws-amplify/datastore";
import { RoleUser, User, UserPoolUser } from "../../../models";
import handleSignUp from "../../../shared/utils/HandleSignUp";
import useUserPool from "../../../shared/hooks/useSetUserPoolID";
import "../Css/users.css";

function InviteUserToField() {
  const location = useLocation();
  const fieldInfo = location.state?.fieldInfo || {};
  const { userPoolId, isLoading: poolLoading, error } = useUserPool(fieldInfo);
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!userPoolId) {
      alert("User Pool ID is not available. Cannot invite user.");
      return;
    }
    setIsLoading(true);
    try {
      const userExists = await DataStore.query(User, (u) => u.email.eq(email));
      if (!userExists.length) {
        const userDetails = {
          email,
          TemporaryPassword: "Initial1!", // This should ideally be generated securely
        };

        const response = await handleSignUp(userDetails);
        console.log("Cognito user creation response:", response);

        const newUser = await DataStore.save(new User({ email: email }));

        await DataStore.save(
          new UserPoolUser({ userPoolId, userId: newUser.id })
        );

        await DataStore.save(
          new RoleUser({
            roleId: "4b2c804a-ae9e-46f5-adaf-5aa5448eb691", // Replace with actual role ID if needed
            userId: newUser.id,
          })
        );

        alert("User created and linked to the field successfully!");
      } else {
        alert(email + " is already in the system. Please enter a new user.");
      }
    } catch (error) {
      console.error("Failed to create user:", error);
      alert("Failed to create user: " + error.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Card className="card-custom-width">
      <Card.Header as="h4">Invite User</Card.Header>
      <Card.Body>
        <Card.Text></Card.Text>
        {error && <div className="alert alert-danger">{error}</div>}
        <Form className="form" onSubmit={handleSubmit}>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Control
              type="email"
              placeholder="Enter User Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </Form.Group>
        </Form>
        <Button
          type="submit"
          className="my-button-style-settings"
          disabled={isLoading || poolLoading}
        >
          {isLoading || poolLoading ? "Submitting..." : "Submit"}
        </Button>
      </Card.Body>
    </Card>
  );
}

export default InviteUserToField;
