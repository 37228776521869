import React from "react";
import { Row, Alert, Card, Button, Collapse } from "react-bootstrap";
import { FaMinus, FaPlus } from "react-icons/fa";
import DimmerControl from "./DimmerControl";
import ColorSelector from "./ColorSelector";
import ControlPanel from "./ControlPanel";
import ColorWheelSelector from "./ColorWheel";
import OfflinePage from "features/Offline/Components/Offline";
import { useLocation } from "react-router-dom";
import { useState } from "react";

function LightingControlsView({
  isDeviceOnline,
  hasActiveScene = {}, // Add default to avoid undefined error
  selectedColors,
  shadowState,
  handleDimmerButtonClick,
  handleColorButtonClick,
  handleControlButtonClick,
  isButtonSelected,
}) {
  const location = useLocation();
  const { fieldInfo = {} } = location.state || {}; // Provide fallback for fieldInfo

  const zones = fieldInfo.zoneValues || [];
  const levelWhiteCommands = fieldInfo.whiteCommands || [];
  const levelRGBCommands = fieldInfo.RGBDimmerCommands || [];
  const RGBCommands = fieldInfo.colorCommands || [];
  const RGBValues = fieldInfo.colorValues || [];
  const hasRadioScenes = fieldInfo.radioScenes || false;
  const hasColorWheel = fieldInfo.hasColorWheel || false;
  const hasRGBDimmers = levelRGBCommands.length > 0;

  const [collapsedZones, setCollapsedZones] = useState(
    Array(zones.length + 5).fill(false)
  );

  // Check if the device is offline and display the message if so
  if (isDeviceOnline.isDeviceOnline === "Offline") {
    return <OfflinePage />; // Show offline message
  }

  // Check if shadowState is still loading
  if (
    Object.keys(shadowState).length === 0 ||
    isDeviceOnline.isDeviceOnline === "Establishing Connection..."
  ) {
    return <div>Loading controls...</div>;
  }

  // Collapse panel toggle function
  const toggleCollapse = (index) => {
    const newCollapsedZones = [...collapsedZones];
    newCollapsedZones[index] = !newCollapsedZones[index];
    setCollapsedZones(newCollapsedZones);
  };

  // Render global control panels (Scenes, Patterns, etc.)
  const renderControlPanels = (types) =>
    types.map(({ type, valuesKey, hasRadioKey }, index) => {
      const panelIndex = zones.length + index;
      return (
        !fieldInfo[hasRadioKey] &&
        fieldInfo[valuesKey].length > 0 && (
          <Card
            key={`zone-container-${type.toLowerCase()}`}
            className="zone-container"
            style={{ minWidth: "300px", width: "400px" }}
          >
            <Card.Header as="h4">
              All Zones
              <Button
                variant="link"
                className="collapse-button"
                onClick={() => toggleCollapse(panelIndex)}
                style={{ float: "right" }}
              >
                {collapsedZones[panelIndex] ? (
                  <FaPlus style={{ fontSize: "30px", color: "white" }} />
                ) : (
                  <FaMinus style={{ fontSize: "30px", color: "white" }} />
                )}
              </Button>
            </Card.Header>
            <Collapse in={!collapsedZones[panelIndex]}>
              <div>
                <ControlPanel
                  values={fieldInfo[valuesKey]}
                  handleControlButtonClick={handleControlButtonClick}
                  isButtonSelected={isButtonSelected}
                  type={type}
                  disabled={hasActiveScene[""]}
                />
              </div>
            </Collapse>
          </Card>
        )
      );
    });

  return (
    <Row className="justify-content-center align-items-center">
      {zones.map((zone, zoneIndex) => {
        const formattedZone = zone.replace(/\s+/g, "");
        return (
          <Card
            key={`zone-container-${zoneIndex}`}
            className="zone-container"
            style={{ minWidth: "300px", width: "400px" }}
          >
            <Card.Header>
              <h4 className="field-card-header">{zone}</h4>
              <Button
                variant="link"
                className="collapse-button"
                onClick={() => toggleCollapse(zoneIndex)}
                style={{ float: "right" }}
              >
                {collapsedZones[zoneIndex] ? (
                  <FaPlus style={{ fontSize: "30px", color: "white" }} />
                ) : (
                  <FaMinus style={{ fontSize: "30px", color: "white" }} />
                )}
              </Button>
            </Card.Header>
            <Collapse in={!collapsedZones[zoneIndex]}>
              <div>
                {Object.values(hasActiveScene).indexOf(true) === 0 &&
                !hasRadioScenes ? (
                  <Alert
                    variant="warning"
                    style={{
                      background: "#222",
                      color: "white",
                      borderColor: "transparent",
                    }}
                  >
                    Scene Active - Manual Controls are Disabled. Select "No
                    Scene" to re-enable.
                  </Alert>
                ) : hasActiveScene[formattedZone] && hasRadioScenes ? (
                  <Alert
                    variant="warning"
                    style={{
                      background: "#222",
                      color: "white",
                      borderColor: "transparent",
                    }}
                  >
                    Scene Active - Manual Controls are Disabled. Select "No
                    Scene" to re-enable.
                  </Alert>
                ) : (
                  <>
                    {zoneIndex < levelWhiteCommands.length && (
                      <DimmerControl
                        zone={zone}
                        dimmerValues={fieldInfo.whiteDimmerValues}
                        dimmerCommands={levelWhiteCommands[zoneIndex]}
                        handleDimmerButtonClick={handleDimmerButtonClick}
                        isButtonSelected={isButtonSelected}
                        type="White"
                        disabled={hasActiveScene[formattedZone]}
                      />
                    )}
                    {zoneIndex < levelRGBCommands.length && (
                      <DimmerControl
                        zone={zone}
                        dimmerValues={fieldInfo.RGBDimmerValues}
                        dimmerCommands={levelRGBCommands[zoneIndex]}
                        handleDimmerButtonClick={handleDimmerButtonClick}
                        isButtonSelected={isButtonSelected}
                        type="Rgb"
                        disabled={hasActiveScene[formattedZone]}
                      />
                    )}
                    {zoneIndex < RGBCommands.length &&
                      (hasColorWheel ? (
                        <ColorWheelSelector
                          zone={zone}
                          RGBValues={RGBValues}
                          RGBCommands={RGBCommands[zoneIndex]}
                          handleColorButtonClick={handleColorButtonClick}
                          isButtonSelected={isButtonSelected}
                          selectedColors={selectedColors}
                          disabled={hasActiveScene[formattedZone]}
                          hasRGBDimmers={hasRGBDimmers}
                        />
                      ) : (
                        <ColorSelector
                          zone={zone}
                          RGBValues={RGBValues}
                          RGBCommands={RGBCommands[zoneIndex]}
                          handleColorButtonClick={handleColorButtonClick}
                          isButtonSelected={isButtonSelected}
                          selectedColors={selectedColors}
                          disabled={hasActiveScene[formattedZone]}
                        />
                      ))}
                  </>
                )}
              </div>
            </Collapse>
          </Card>
        );
      })}

      {renderControlPanels([
        { type: "Scene", valuesKey: "sceneValues", hasRadioKey: "radioScenes" },
        {
          type: "Pattern",
          valuesKey: "patternValues",
          hasRadioKey: "radioPatterns",
        },
        {
          type: "Effect",
          valuesKey: "effectValues",
          hasRadioKey: "radioEffects",
        },
        { type: "Music", valuesKey: "musicValues", hasRadioKey: "radioMusic" },
        {
          type: "Schedule",
          valuesKey: "scheduleValues",
          hasRadioKey: "radioSchedule",
        },
      ])}
    </Row>
  );
}

export default LightingControlsView;
