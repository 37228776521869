import * as React from "react";
import { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import "@aws-amplify/ui-react/styles.css";
import { RoleUser, User, UserPool, UserPoolUser } from "../../../../models";
import { DataStore } from "@aws-amplify/datastore";
import handleSignUp from "../../../../shared/utils/HandleSignUp";
import "../Css/willAccess.css";

function InviteUser() {
  const [userPools, setUserPools] = useState([]);
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    async function fetchData() {
      setIsLoading(true);
      try {
        const pools = await DataStore.query(UserPool);
        setUserPools(
          pools
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((pool) => ({ ...pool, selected: false }))
        );
      } catch (error) {
        console.error("Error loading user pools:", error);
      } finally {
        setIsLoading(false);
      }
    }
    fetchData();
  }, []);

  const handleCheckboxChange = (id) => {
    setUserPools((prevPools) =>
      prevPools.map((pool) =>
        pool.id === id ? { ...pool, selected: !pool.selected } : pool
      )
    );
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    try {
      const userExists = await DataStore.query(User, (u) => u.email.eq(email));
      if (!userExists.length) {
        // Use the handleSignUp function
        const userDetails = {
          email,
          TemporaryPassword: "Initial1!", // This should ideally be generated securely
        };

        const response = await handleSignUp(userDetails);
        console.log("Cognito user creation response:", response);

        const newUser = await DataStore.save(new User({ email: email }));

        const selectedPools = userPools.filter((pool) => pool.selected);
        for (const pool of selectedPools) {
          await DataStore.save(
            new UserPoolUser({ userPoolId: pool.id, userId: newUser.id })
          );
        }

        await DataStore.save(
          new RoleUser({
            roleId: "4b2c804a-ae9e-46f5-adaf-5aa5448eb691",
            userId: newUser.id,
          })
        );

        alert("User created and linked to selected pools successfully!");
      } else {
        alert(email + " is already in the system. Please enter a new user.");
      }
    } catch (error) {
      console.error("Failed to create user:", error);
      alert("Failed to create user: " + error.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Card className="will-card">
      <Card.Header as="h4">Invite User</Card.Header>
      <Card.Body>
        <Card.Title as="h6">Enter User Email:</Card.Title>
        <Form.Group
          className="mb-3"
          controlId="formBasicEmail"
          style={{ width: "100%" }}
        >
          <Form.Control
            type="email"
            placeholder="Enter email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </Form.Group>
        <Card.Title as="h6">Select Fields User Can Access:</Card.Title>
        <Row>
          {userPools.map((pool) => (
            <Col md={6}>
              <Form.Check
                key={pool.id}
                type="checkbox"
                className="will-check"
                label={pool.name}
                name={pool.name}
                onChange={() => handleCheckboxChange(pool.id)}
                checked={pool.selected}
              />
            </Col>
          ))}
        </Row>
        <Button
          onClick={handleSubmit}
          className="my-button-style-will"
          disabled={isLoading}
        >
          {isLoading ? "Loading..." : "Submit"}
        </Button>
      </Card.Body>
    </Card>
  );
}

export default InviteUser;
