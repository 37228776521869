import React from "react";
import { Card, Button, Row } from "react-bootstrap";
import { getRGBColor } from "../Utils/ColorUtility";
import { getBackgroundColor } from "../Utils/getBackgroundColor";

const ColorSelector = ({
  zone,
  RGBValues,
  RGBCommands,
  handleColorButtonClick,
  isButtonSelected,
  selectedColors,
  shadowState,
}) => {
  return (
    <Card.Body>
      <Card.Title as="h6" style={{ color: "white" }}>
        Color Selector
      </Card.Title>
      <Row style={{ justifyContent: "center" }}>
        {RGBValues.map((value, i) => {
          const rgbColor = getRGBColor(i); // Use the utility function to get the color
          return (
            <Button
              key={`color-button-${zone}-${i}`} // Added unique key using zone and index
              className={`color-button-style ${isButtonSelected(
                zone,
                "color",
                value
                  .split(",")
                  .map((v) => `#${v}`)
                  .join(",")
              )}`}
              style={{
                backgroundColor: rgbColor,
                color: "white",
                minWidth: "70px",
                maxWidth: "80px",
              }}
              onClick={() => {
                handleColorButtonClick(
                  zone,
                  RGBCommands,
                  value
                    .split(",")
                    .map((v) => `#${v}`)
                    .join(",")
                );
              }}
            >
              {/* Empty button content to serve as color block */}
            </Button>
          );
        })}
      </Row>

      <div
        className="d-flex align-items-center justify-content-center"
        style={{
          width: "70%",
          borderRadius: "10px",
          height: "20px",
          backgroundColor: getBackgroundColor(zone, RGBValues, selectedColors),
          margin: "20px auto",
          color: "black",
        }}
      ></div>

      <Button
        key={`color-no-${zone}`} // Ensuring unique key for 'No Color' button
        className={`no-blank ${
          isButtonSelected(zone, "color", "#0,#0,#0") ? "selected-button" : ""
        }`}
        onClick={() => {
          handleColorButtonClick(zone, RGBCommands, "#0,#0,#0");
        }}
      >
        No Color
      </Button>
    </Card.Body>
  );
};

export default ColorSelector;
