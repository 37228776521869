import React, { useState, useEffect } from "react";
import ScheduleForm from "./ScheduleForm";
import ScheduleList from "./ScheduleList";
import { useLocation } from "react-router-dom";
import axios from "axios";
import Loading from "features/Loading/Components/Loading.js";

const Scheduler = () => {
  const location = useLocation();
  const fieldInfo = location.state?.fieldInfo || {};
  const iotName = fieldInfo.IOTThingName || "";
  const [schedules, setSchedules] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchSchedules = async () => {
    try {
      const response = await axios.get(
        `https://j3pp969u1j.execute-api.us-west-2.amazonaws.com/prod/retrieve_schedules`,
        {
          params: { iotName },
        }
      );
      setSchedules(Array.isArray(response.data) ? response.data : []);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching schedules:", error);
      setSchedules([]);
    }
  };

  useEffect(() => {
    fetchSchedules();
  }, [iotName]);

  const addSchedule = async (newSchedule) => {
    try {
      await axios.post(
        `https://j3pp969u1j.execute-api.us-west-2.amazonaws.com/prod/create_schedule`,
        newSchedule
      );
      fetchSchedules(); // Reload schedules after adding a new one
    } catch (error) {
      console.error("Error adding schedule:", error);
    }
  };

  return (
    <>
      <ScheduleForm
        addSchedule={addSchedule}
        fetchSchedules={fetchSchedules}
        fieldInfo={fieldInfo}
      />
      <ScheduleList
        schedules={schedules}
        iotName={iotName}
        fetchSchedules={fetchSchedules}
      />
    </>
  );
};

export default Scheduler;
