import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { DataStore } from "@aws-amplify/datastore";
import { useEffect, useState } from "react";
import { User, UserPool, UserPoolUser } from "../../../../models";
import { fetchUserPermissions } from "../../../../shared/utils/Utils";
import { Card } from "react-bootstrap";

// Component to manage user permissions for different pools
function ManageUser() {
  // State to store user list and their loading status
  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  // State for the currently selected user
  const [selectedUser, setSelectedUser] = useState(null);
  // State for managing the list of user pools and their selection status
  const [userPools, setUserPools] = useState([]);

  // Effect to load users and user pools on component mount
  useEffect(() => {
    async function fetchData() {
      setIsLoading(true);
      try {
        // Fetch all users and sort by email
        const userResults = await DataStore.query(User);
        setUsers(userResults.sort((a, b) => a.email.localeCompare(b.email)));

        // Fetch all user pools and sort by name, initializing all as unselected
        const pools = await DataStore.query(UserPool);
        setUserPools(
          pools
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((pool) => ({ ...pool, selected: false }))
        );
      } catch (error) {
        console.error("Error loading users and user pools:", error);
      } finally {
        setIsLoading(false);
      }
    }
    fetchData();
  }, []);

  // Handle user selection from dropdown
  const handleSelectChange = async (event) => {
    const userEmail = event.target.value;
    const user = users.find((u) => u.email === userEmail);
    setSelectedUser(user);
    if (user) {
      // Fetch permissions for the selected user
      const permissions = await fetchUserPermissions(user.id);
      setUserPools((prevPools) =>
        prevPools.map((pool) => ({
          ...pool,
          selected: permissions.some((p) => p.userPoolId === pool.id),
        }))
      );
    }
  };

  // Toggle selection status of user pools on checkbox change
  const handleCheckboxChange = (id) => {
    setUserPools((prevPools) =>
      prevPools.map((pool) =>
        pool.id === id ? { ...pool, selected: !pool.selected } : pool
      )
    );
  };

  // Handle form submission to update user's pool permissions
  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!selectedUser) {
      console.error("No user selected");
      return;
    }

    // Remove existing associations
    const existingAssociations = await DataStore.query(UserPoolUser, (c) =>
      c.userId.eq(selectedUser.id)
    );
    await Promise.all(
      existingAssociations.map((assoc) => DataStore.delete(assoc))
    );

    // Create new associations based on selected checkboxes
    const selectedPools = userPools.filter((pool) => pool.selected);
    for (const pool of selectedPools) {
      await DataStore.save(
        new UserPoolUser({ userPoolId: pool.id, userId: selectedUser.id })
      );
    }

    console.log("Updated user pools for:", selectedUser.email);
    alert("User pools updated.");
  };

  // Render loading state or the main content
  if (isLoading) {
    return <Card>Fetching Users...</Card>;
  }

  return (
    <Card className="will-card">
      <Card.Header as="h4">Manage Users</Card.Header>
      <Card.Body>
        <Card.Title as="h6">Select User:</Card.Title>
        <Form.Select className="mb-3" onChange={handleSelectChange}>
          <option value={selectedUser ? selectedUser.email : ""}>User</option>
          {users.map((user) => (
            <option key={user.id} value={user.email}>
              {user.email}
            </option>
          ))}
        </Form.Select>

        <Card.Title as="h6">Select Fields User Can Access:</Card.Title>
        <Row>
          {userPools.map((pool) => (
            <Col md={6}>
              <Form.Check
                key={pool.id}
                type="checkbox"
                className="will-check"
                label={pool.name}
                name={pool.name}
                onChange={() => handleCheckboxChange(pool.id)}
                checked={pool.selected}
              />
            </Col>
          ))}
        </Row>

        <Button onClick={handleSubmit} className="my-button-style-will">
          Submit
        </Button>
      </Card.Body>
    </Card>
  );
}

export default ManageUser;
