import { useState, useEffect } from "react";
import { getUserInfo } from "../utils/Utils";

/**
 * Custom hook to check if the current user has admin privileges and their role.
 * It provides boolean states for isAdmin and isWill.
 */
const useCheckAdminStatus = () => {
  const [isAdmin, setIsAdmin] = useState(false);
  const [isWill, setIsWill] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const checkAdminStatus = async () => {
      try {
        const userInfo = await getUserInfo();
        setIsAdmin(userInfo.isAdmin);
        setIsWill(userInfo.currentRole === "WiLL");
      } catch (err) {
        console.error("Error checking admin status:", err);
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    checkAdminStatus();
  }, []);

  return { isAdmin, isWill, loading, error };
};

export default useCheckAdminStatus;
