import { Heading, useTheme } from "@aws-amplify/ui-react";

/**
 * Defines the header element of the sign in screen for authentication 
 */
function SignInHeader() {
    const { tokens } = useTheme();

    return (
        <div style={{ borderTop: "4px solid black", borderImage: "linear-gradient(to right, black 50%, #FFCF2E 50%, #FFCF2E 100%) 2"}}> 
            <Heading style={{ color: "black", textAlign: "center", width: "100%"}} className="bolded" level={4} padding={`${tokens.space.medium} ${tokens.space.medium} 0`}>
                Sign in to your Account
            </Heading>
        </div>
    );
}

export default SignInHeader;