import { useState, useEffect } from "react";
import { DataStore } from "@aws-amplify/datastore";
import { UserPool } from "../../models"; // Make sure the path is correct

const useUserPool = (fieldId) => {
  const [userPoolId, setUserPoolId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const pools = await DataStore.query(UserPool);
        const matchingPool = pools.find(
          (pool) => pool.userPoolFieldId === fieldId.id
        );
        if (matchingPool) {
          setUserPoolId(matchingPool.id);
        } else {
          console.error("User Pool not found for the field");
          setError("User Pool not found for the field");
        }
      } catch (err) {
        console.error("Error loading user pools:", err);
        setError("Error loading user pools");
      } finally {
        setIsLoading(false);
      }
    };

    if (fieldId) {
      fetchData();
    }
  }, [fieldId]);

  return { userPoolId, isLoading, error };
};

export default useUserPool;
