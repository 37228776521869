import { useState, useEffect } from "react";
import { getUserInfo } from "../utils/Utils";

/**
 * Custom hook to fetch the user's fields from the server.
 * It provides the fields array and a loading state.
 */
const useFetchFields = () => {
  const [fields, setFields] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchFields = async () => {
      try {
        const userInfo = await getUserInfo();
        setFields(userInfo.usersFields);
      } catch (err) {
        console.error("Error fetching fields:", err);
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchFields();
  }, []);

  return { fields, loading, error };
};

export default useFetchFields;
