import React from "react";
import { Card, Button, Row } from "react-bootstrap";

const ControlPanel = ({
  zone,
  values,
  handleControlButtonClick,
  isButtonSelected,
  type,
}) => {
  function capitalizeFirstLetter(string) {
    if (!string) return "";
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  // Determine if the zone should be displayed or not
  const displayZone = zone ? ` - ${zone}` : "";

  // Check if the type should be rendered for the current zone
  const shouldRenderButtons = !(zone === "Security");

  return (
    shouldRenderButtons && (
      <Card.Body>
        <Card.Title
          as="h6"
          style={{ color: "white" }}
        >{`${type}${displayZone}`}</Card.Title>
        <Row style={{ justifyContent: "center", width: "110%" }}>
          {values.map((val, index) => (
            <Button
              key={`${type}-${zone ? `${zone}-` : ""}${index}`} // Conditionally include zone in key
              className={`button-style ${
                isButtonSelected(zone, type, val) ? "selected-button" : ""
              }`}
              onClick={() => handleControlButtonClick(zone, type, val)}
            >
              {val} {/* Display the value directly */}
            </Button>
          ))}
        </Row>
        <Button
          key={`no-${type}-${zone ? `${zone}` : ""}`} // Conditionally include zone in key
          onClick={() => handleControlButtonClick(zone, type, `No ${type}`)}
          className={`no-blank ${
            isButtonSelected(zone, type, `No ${type}`) ? "selected-button" : ""
          }`}
        >
          No {capitalizeFirstLetter(type)}
        </Button>
      </Card.Body>
    )
  );
};

export default ControlPanel;
