import { fetchUserAttributes } from "@aws-amplify/auth";
import { DataStore } from "@aws-amplify/datastore";
import {
  Field,
  Role,
  RoleUser,
  User,
  UserPool,
  UserPoolUser,
} from "../../models";
import { fetchAuthSession } from "@aws-amplify/auth";

export async function getCurrentUser() {
  try {
    const attributes = await fetchUserAttributes();
    const emailAttribute = attributes.email;
    return emailAttribute;
  } catch (error) {
    console.error("Error fetching current user:", error);
    return null;
  }
}

export function log(level, message) {
  const url =
    "https://59gnydu8qb.execute-api.us-west-2.amazonaws.com/beta/willcloud_logging_test_2";
  const meta = {
    agent: navigator.userAgent,
    language: navigator.language,
    platform: navigator.platform,
    level: level,
    source: "WiLLCloud",
  };

  fetch(url, {
    mode: "no-cors",
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ meta, message }),
  }).catch((e) => console.error("Error sending log:", e));
}

export async function LogShadowUpdate(updateMessage, projectName) {
  try {
    const currentUser = await getCurrentUser();
    if (!currentUser) {
      console.error("Error: Current user is not available for logging.");
      return;
    }

    const date = new Date();
    log(
      "shadow_update_" + projectName.toLowerCase(),
      "User " +
        currentUser +
        " published the following update message: " +
        JSON.stringify(updateMessage) +
        " at the following time: " +
        date.toISOString()
    );
  } catch (e) {
    console.error("Error logging shadow update:", e);
  }
}

/*
 */

// Helper functions to modularize the logic
async function fetchCurrentUser(email) {
  const users = await DataStore.query(User, (u) => u.email.eq(email));
  if (!users.length) {
    console.error("No user found with the email:", email);
    return null;
  }
  return users[0];
}

async function updateUserCognitoId(currentUser, id) {
  if (currentUser.cognitoID === null || currentUser.cognitoID !== id) {
    /*const url =
      "https://bbe73futrfh6kr3f7rwu6xxd7q0aumca.lambda-url.us-west-2.on.aws/";
    const meta = {
      agent: navigator.userAgent,
      language: navigator.language,
      platform: navigator.platform,
      source: "WiLLCloud",
    };
    const message = { sent: "sent from frontend" };
    await fetch(url, {
      mode: "no-cors",
      method: "POST",
      body: JSON.stringify({ meta, message }),
    });*/ //commenting out right now as the call isnt working correctly.
    // There is actually a trigger in the DynamoDB table that seems to be working. Thus this shouldn't be needed.
    await DataStore.save(
      User.copyOf(currentUser, (user) => (user.cognitoID = id))
    );
  }
}

export async function fetchUserPermissions(userId) {
  const allPermissions = await DataStore.query(UserPoolUser);
  return allPermissions.filter((permission) => permission.userId === userId);
}

async function fetchUserFields(currentPermissions) {
  const fields = [];
  const allFields = await DataStore.query(Field);
  for (let field of allFields) {
    for (let permission of currentPermissions) {
      const userPool = await permission.userPool;
      if (field.id === userPool.userPoolFieldId) {
        fields.push(field);
        break;
      }
    }
  }
  return fields;
}

async function checkAdminStatus(currentPermissions) {
  for (const permission of currentPermissions) {
    const userPool = await DataStore.query(UserPool, permission.userPoolId);
    if (userPool.isAdmin) {
      return true;
    }
  }
  return false;
}

function throttle(func, limit) {
  let lastFunc;
  let lastRan;
  return function () {
    const context = this;
    const args = arguments;
    if (!lastRan) {
      func.apply(context, args);
      lastRan = Date.now();
    } else {
      clearTimeout(lastFunc);
      lastFunc = setTimeout(function () {
        if (Date.now() - lastRan >= limit) {
          func.apply(context, args);
          lastRan = Date.now();
        }
      }, limit - (Date.now() - lastRan));
    }
  };
}

const throttledUpdateUserCognitoId = throttle(updateUserCognitoId, 10000);

export async function getUserInfo() {
  const info = await fetchUserAttributes();
  let id = await fetchAuthSession();
  id = info.sub;

  const currentUser = await fetchCurrentUser(info.email);
  if (!currentUser) return {};

  // Using the throttled function
  throttledUpdateUserCognitoId(currentUser, id);

  const currentPermissions = await fetchUserPermissions(currentUser.id);
  const usersFields = await fetchUserFields(currentPermissions);
  const isAdmin = await checkAdminStatus(currentPermissions);

  const roleUsers = await DataStore.query(RoleUser);
  const roles = await DataStore.query(Role);
  const currentUserPoolUsers = await DataStore.query(UserPoolUser, (u) =>
    u.userId.eq(currentUser.id)
  );

  const currentRoleUser = roleUsers.find((u) => u.userId === currentUser.id);
  const currentRole = roles.find((u) => u.id === currentRoleUser.roleId);

  return {
    currentUser,
    currentPermissions,
    usersFields,
    isAdmin,
    currentUserPoolUsers,
    currentRole: currentRole ? currentRole.name : undefined,
  };
}
