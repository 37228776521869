import { CognitoIdentityProviderClient, AdminCreateUserCommand
} from '@aws-sdk/client-cognito-identity-provider'// ES Modules import
import { fetchAuthSession } from '@aws-amplify/auth';
/*
TODO: 
- Figure out how to not use fetchAuth due to security concerns

INFO:
- exclude messageAction from documentation as RESEND breaks and SUPPRESS doesn't send the invite email.
*/

async function HandleSignUp(userDetails) {
  const { email, TemporaryPassword } = userDetails;

  const credentals = (await fetchAuthSession()).credentials

  const client = new CognitoIdentityProviderClient({
    region: "us-west-2",
    credentials: credentals
});

  try {
    const input = {
      UserPoolId: "us-west-2_qj2MtFaJh", // Make sure this is your actual User Pool ID
      Username: email,
      UserAttributes: [
        { Name: "email", Value: email }, // Correct usage for email attribute
      ],
      TemporaryPassword,
      DesiredDeliveryMediums: ["EMAIL"],
    };

    const command = new AdminCreateUserCommand(input);
    const response = await client.send(command);

    return response;
  } catch (e) {
    console.error("Error creating user in Cognito:", e);
    throw e; // Optionally re-throw to handle it in the calling function
  }
}

export default HandleSignUp;
