import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import InviteUser from "./InviteUserPage";
import ManageUser from "./ManagePermissions";
import "../../Css/settings.css";

function UserManLayout() {
  return (
    <Row style={{ display: "flex", justifyContent: "center" }}>
      <Col xs={12} md={6} lg={6}>
        <InviteUser />
      </Col>
      <Col xs={12} md={6} lg={6}>
        <ManageUser />
      </Col>
    </Row>
  );
}

export default UserManLayout;
