import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { withAuthenticator } from "@aws-amplify/ui-react";

// Importing shared component-specific styles
import "shared/css/custom-auth-styles.css"; // Styles specific to authentication components

// Import components
import Header from "features/Layout/Components/Header";
import Footer from "features/Layout/Components/Footer";
import SignInHeader from "features/Login/Components/SignInHeader";
import SignInFooter from "features/Login/Components/SignInFooter";
import SignUpHeader from "features/Login/Components/SignUpHeader";
import SignUpFooter from "features/Login/Components/SignUpFooter";
import FieldSelector from "features/Selector/Components/FieldSelector";
import Layout from "features/Layout/Components/Layout";
import FieldLoader from "features/FieldStatus/Components/FieldStatus";
import LightingControls from "features/Controls/Components/LightingControlsContainer";
import Scheduler from "features/Scheduler/Components/Scheduler";
import UserManLayout from "features/Settings/WiLLAccess/Components/Layout";
import Account from "features/Users/Components/Layout";

function App({ signOut, user }) {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<FieldSelector />} />
          <Route path=":field" element={<FieldLoader />}>
            <Route index element={<LightingControls />} />
            <Route path="controls" element={<LightingControls />} />
            <Route path="schedule" element={<Scheduler />} />
            <Route path="account" element={<Account />} />
          </Route>
          <Route path="Manage" element={<UserManLayout />} />
          <Route path="/radiofield" element={<Navigate to="/" />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default withAuthenticator(App, {
  components: {
    Header,
    SignIn: {
      Header: SignInHeader,
      Footer: SignInFooter,
    },
    ConfirmSignUp: {
      Header: SignUpHeader,
      Footer: SignUpFooter,
    },
    Footer,
  },
  hideSignUp: true,
  className: "custom-sign-in", // Apply the custom CSS class from custom-auth-styles.css
});
